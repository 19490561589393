<script>
import UserService from "@/services/UserService";
import VUserMeForm from "@/components/Forms/VUserMeForm";
import VUserPasswordForm from "@/components/Forms/VUserPasswordForm";
import VPictureUpload from "@/components/Widgets/VPictureUpload.vue";
import AppCfg from "@/mixins/AppCfg";

import { mapMutations } from "vuex";

export default {
    name: "VMyAccountPage",
    components: {
        VUserMeForm,
        VUserPasswordForm,
        VPictureUpload,
    },
    mixins: [AppCfg],
    data() {
        return {
            loading: false,
            editing: false,
            changingPassword: false,
            user: null,
            security: null,
            locale: null,
            localeLoading: false,
        };
    },
    computed: {
        is_mobile() {
            const isMobile = window.matchMedia(
                "only screen and (max-width: 768px)"
            );
            return isMobile.matches ? true : false;
        },
        enforceOAuth2() {
            if (process.env.VUE_APP_ENFORCE_OAUTH2 === "true") {
                return true;
            } else {
                return false;
            }
        }
    },
    async created() {
        await this.fetchMe();
        this.locale = this.user.locale;
        this.$watch("locale", this.changeLocale);
    },
    methods: {
        ...mapMutations("auth", ["updateAvatarUrl"]),
        async changeLocale(newLocale) {
            this.localeLoading = true;
            this.loading = true;
            try {
                const r = await UserService.POST_changeLocale(newLocale);
                await this.$store.dispatch("auth/refreshAuthUserData");
                await this.$store.dispatch("backend/fetchConstants");
                window.location.reload();
            } catch (e) {}
        },
        async fetchMe() {
            try {
                this.loading = true;
                const r = await UserService.me();
                this.user = r.data.data;
                this.security = r.data.meta.security;
            } catch (e) {
                console.log("K", e);
            }
            this.loading = false;
        },
        closeSave() {
            this.editing = false;
            this.fetchMe();
        },
        updateAvatar(new_url) {
            this.user.avatar = new_url;
            this.updateAvatarUrl(new_url);
            console.log("Updated avatar: ", new_url, this.user);
        },
    },
};
</script>

<template>
    <div class="v-my-account">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <div
                class="alert alert-warning alert-outline text-center"
                v-if="localeLoading"
            >
                <v-loader :loading="true" />

                <h3>{{ $t("user.applying_language_changes_please_wait") }}</h3>
            </div>
            <template v-else>
                <v-loader :loading="loading" v-if="loading" />
                <template v-else>
                    <div class="row card-wrapper">
                        <div class="col-12 col-md-6">
                            <card>
                                <h3>{{ $t("profile.my_data") }}</h3>
                                <dl>
                                    <dt>{{ $t("user.name") }}</dt>
                                    <dd>{{ user.name }}</dd>

                                    <dt>{{ $t("user.email") }}</dt>
                                    <dd>{{ user.email }}</dd>

                                    <dt>{{ $t("user.role") }}</dt>
                                    <dd>{{ user.acl_role_name }}</dd>

                                    <dt>{{ $t("user.system_language") }}</dt>
                                    <dd class="mt-2">
                                        <FormulateInput
                                            type="select"
                                            v-model="locale"
                                            :options="appCfgLangsSelect"
                                        />
                                    </dd>
                                </dl>

                                <div class="text-right mt-5">
                                    <base-button
                                        v-if="!enforceOAuth2"
                                        type="warning"
                                        :block="is_mobile"
                                        @click="changingPassword = true"
                                        >{{
                                            $t("user.change_password")
                                        }}</base-button
                                    >

                                    <base-button
                                        type="primary"
                                        :block="is_mobile"
                                        @click="editing = true"
                                        >{{
                                            $t("user.update_data")
                                        }}</base-button
                                    >
                                </div>
                            </card>
                        </div>
                        <div class="col-12 col-md-6">
                            <card>
                                <h3>{{ $t("profile.my_picture") }}</h3>

                                <v-picture-upload
                                    :avatar="user.avatar"
                                    @avatar="updateAvatar"
                                />
                            </card>
                        </div>

                        <div class="col-12 col-md-6 offset-md-6">
                            <card>
                                <h3>
                                    {{ $t("profile.security_information") }}
                                </h3>
                                <p>
                                    {{ $t("user.security_disclaimer") }}
                                </p>

                                <dl>
                                    <dt>{{ $t("user.sign_in_count") }}</dt>
                                    <dd>{{ $n(security.sign_count) }}</dd>

                                    <dt>{{ $t("user.last_sign_in") }}</dt>
                                    <dd>
                                        {{
                                            $d(
                                                new Date(security.last_sign_in),
                                                "long"
                                            )
                                        }}
                                    </dd>

                                    <dt>{{ $t("user.last_sign_in_ip") }}</dt>
                                    <dd>{{ security.last_ip }}</dd>
                                </dl>
                            </card>
                        </div>
                    </div>
                </template>
            </template>
        </div>
        <portal to="modals">
            <v-user-password-form
                v-if="changingPassword"
                :context="user"
                @close="changingPassword = false"
            />
            <v-user-me-form
                v-if="editing"
                :context="user"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>
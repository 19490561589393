<script>
import LoginService from "@/services/LoginService";
import VPasswordScore from "@/components/VPasswordScore.vue";
import { zxcvbn } from "zxcvbn3";

export default {
    components: {
        VPasswordScore,
    },
    props: {
        token: {},
        action: {},
    },
    data() {
        return {
            loading: false,
            buttonLoading: false,
            error: false,
            tokenVerified: false,
            model: {
                password: "",
                confirm: "",
            },
            passwordScore: 0,
            passwordFeedback: null,
            notEqual: false,
            errors: null,
            passwordSuccess: false,
        };
    },
    watch: {
        model: {
            handler: function (n) {
                this.updateScore(n.password);
                if (
                    n.password.length > 0 &&
                    n.confirm.length > 0 &&
                    n.confirm == n.password
                ) {
                    this.notEqual = false;
                } else {
                    this.notEqual = true;
                }
            },
            deep: true,
        },
    },
    computed: {
        isValid: function () {
            if (
                this.model.password.length > 6 &&
                this.notEqual === false &&
                this.passwordScore == 4
            ) {
                return true;
            } else {
                return false;
            }
        },
        enforceOAuth2() {
            if (process.env.VUE_APP_ENFORCE_OAUTH2 === "true") {
                return true;
            } else {
                return false;
            }
        }
    },
    created() {
        this.checkToken();
    },
    methods: {
        rulePasswordsMatch(ctx) {
            if (ctx.value == this.model.password) {
                return true;
            } else {
                return false;
            }
        },
        updateScore(password) {
            let zx = zxcvbn(password);
            this.passwordScore = zx.score;
            this.passwordFeedback = zx.feedback;
        },
        async checkToken() {
            try {
                this.loading = true;
                const r = await LoginService.verifyToken(this.token);
                if (r.data.data.token == this.token) {
                    this.tokenVerified = true;
                }
            } catch (e) {
                this.tokenVerified = false;
            }
            this.loading = false;
        },
        async onSubmit() {
            if (this.isValid) {
                this.buttonLoading = true;
                try {
                    this.buttonLoading = true;
                    const r = await LoginService.password({
                        token: this.token,
                        password: this.model.password,
                    });
                    this.passwordSuccess = true;
                } catch (e) {}

                //this.buttonLoading = false;
            }
        },
    },
};
</script>

<template>
    <div v-if="!enforceOAuth2">
        <!-- Header -->
        <div class="header py-7">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                            <img src="/img/logo/meg-logo-white.png" style="max-width: 200px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page content -->
        <transition name="fade">
            <template v-if="passwordSuccess === false">
                <div class="container mt--8 pb-5">
                    <div class="row justify-content-center">
                        <div class="col-md-8">
                            <div class="card bg-secondary border-0 mb-0">
                                <template v-if="loading">
                                    <div class="mt-4 mb-4">
                                        <v-loader :loading="loading" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="card-header bg-transparent">
                                        <template v-if="action == 'create'">
                                            {{
                                                $t(
                                                    "login.create_password_title"
                                                )
                                            }}
                                        </template>

                                        <template v-if="action == 'reset'">
                                            {{
                                                $t("login.reset_password_title")
                                            }}
                                        </template>
                                    </div>

                                    <div
                                        class="card-body px-lg-5 py-lg-5"
                                        v-if="tokenVerified === false"
                                    >
                                        <div
                                            class="alert alert-danger alert-outline"
                                        >
                                            {{
                                                $t(
                                                    "login.your_request_has_expired_or_does_not_exist"
                                                )
                                            }}
                                        </div>
                                    </div>

                                    <div class="card-body px-lg-5" v-else>
                                        <p
                                            v-if="action == 'create'"
                                            class="mb-4"
                                        >
                                            {{
                                                $t(
                                                    "login.create_password_intro"
                                                )
                                            }}
                                        </p>

                                        <p
                                            v-if="action == 'reset'"
                                            class="mb-4"
                                        >
                                            {{
                                                $t("login.reset_password_intro")
                                            }}
                                        </p>

                                        <FormulateForm
                                            @submit="onSubmit"
                                            :errors="errors"
                                        >
                                            <FormulateInput
                                                autocomplete="new-password"
                                                type="password"
                                                name="password"
                                                v-model="model.password"
                                                :label="
                                                    $t('login.new_password')
                                                "
                                                :placeholder="
                                                    $t('login.password')
                                                "
                                                validation="required"
                                                :validation-name="
                                                    $t('password.password')
                                                "
                                            />

                                            <FormulateInput
                                                autocomplete="new-password"
                                                type="password"
                                                v-model="model.confirm"
                                                name="confirm"
                                                :label="
                                                    $t('login.confirm_password')
                                                "
                                                :placeholder="
                                                    $t('login.confirm_password')
                                                "
                                                validation="passwordsMatch"
                                                :validation-name="
                                                    $t('password.password')
                                                "
                                                :validation-rules="{
                                                    passwordsMatch:
                                                        rulePasswordsMatch,
                                                }"
                                                :validation-messages="{
                                                    passwordsMatch: $t(
                                                        'password.passwords_do_not_match'
                                                    ),
                                                }"
                                            />

                                            <v-password-score
                                                :score="passwordScore"
                                                :feedback="passwordFeedback"
                                            />

                                            <div class="text-center">
                                                <base-button
                                                    :disabled="!isValid"
                                                    type="primary"
                                                    native-type="submit"
                                                    class="my-4"
                                                    :loading="buttonLoading"
                                                >
                                                    <template
                                                        v-if="
                                                            action == 'create'
                                                        "
                                                    >
                                                        {{
                                                            $t(
                                                                "login.create_account"
                                                            )
                                                        }}
                                                    </template>
                                                    <template
                                                        v-if="action == 'reset'"
                                                    >
                                                        {{
                                                            $t(
                                                                "login.reset_password"
                                                            )
                                                        }}
                                                    </template>
                                                </base-button>
                                            </div>
                                        </FormulateForm>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- .c -->
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="container mt--8 pb-5">
                    <div class="row justify-content-center">
                        <div class="col-md-8">
                            <div class="card bg-secondary border-0 mb-0">
                                <div class="card-header bg-transparent">
                                    <template v-if="action == 'create'">
                                        {{ $t("login.create_password") }}
                                    </template>

                                    <template v-if="action == 'reset'">
                                        {{ $t("login.reset_password") }}
                                    </template>
                                </div>

                                <div class="card-body px-lg-5 py-lg-5">
                                    <p v-if="action == 'reset'">
                                        {{
                                            $t(
                                                "password.password_changed_success"
                                            )
                                        }}
                                    </p>

                                    <p v-if="action == 'create'">
                                        {{
                                            $t(
                                                "password.password_created_success"
                                            )
                                        }}
                                    </p>

                                    <router-link
                                        to="/login"
                                        class="btn btn-lg btn-primary mt-4"
                                        >{{
                                            $t("login.go_to_login_page")
                                        }}</router-link
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </transition>
    </div>
</template>

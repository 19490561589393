import Http from "@/libraries/Http";

export default {
    apiVersion: "v1",
    RESTroute: "project-milestones",

    async GET_mfaStatus(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/mfa-status`, {
            params: params
        });
    },

    async POST_preview(data, params = {}) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/preview`,
            data,
            { params: params }
        );
    },

    async POST_sandboxRecalculate(data, params = {}) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/sandbox-recalculate`,
            data,
            { params: params }
        );
    },

    async GET_timelineData(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/timeline-data/${project_id}`,
            { params: params }
        );
    },

    async PUT_revertStatusChangeRequest(project_milestone_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/revert-status-change-request/${project_milestone_id}`
        );
    },

    async PUT_resetStatusChangeRequest(project_milestone_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/reset-status-change-request/${project_milestone_id}`
        );
    },

    async PUT_confirmStatusChangeRequest(project_milestone_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/confirm-status-change-request/${project_milestone_id}`
        );
    },

    async PUT_createStatusChangeRequest(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/create-status-change-request`,
            data
        );
    },

    async GET_milestoneBoard(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/milestone-board/${project_id}`,
            { params: params }
        );
    },

    // Current support
    async PUT_flipStatus(project_milestone_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/flip-status/${project_milestone_id}`
        );
    },

    async POST_addToProject(project_id, data, params = {}) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/add-to-project/${project_id}`,
            data,
            { params: params }
        );
    },

    async GET_getForAdding(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/get-for-adding/${project_id}`,
            { params: params }
        );
    },

    async GET_dropdown(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/dropdown/${project_id}`,
            {
                params: params
            }
        );
    },

    async GET_fullDropdown(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/full-dropdown/${project_id}`,
            {
                params: params
            }
        );
    },

    async GET_invoicingDropdown(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/invoicing-dropdown/${project_id}`,
            {
                params: params
            }
        );
    },
    /**
     * Get all resources
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {
            params: params
        });
    },

    /**
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {
            params: params
        });
    },

    /**
     * Create resource
     * POST /resource
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /**
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/${data.id}`,
            data
        );
    },

    /**
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }
};

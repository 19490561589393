<script>
import UserService from "@/services/UserService";
import LoginService from "@/services/LoginService";
import CurrencyService from "@/services/CurrencyService";
import DepartmentService from "@/services/DepartmentService";
import PriceRoleService from "@/services/PriceRoleService";
import MGConsts from "@/mixins/MGConsts";
import Messages from "@/mixins/Messages";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VUserForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        }
    },
    mixins: [MGConsts, Messages],
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            roles: [],
            roles_help: [],
            currencies: [],
            departments: [],
            price_roles: [],
            user: {
                id: null,
                name: null,
                email: null,
                active: null,
                acl_role: null,
                invite: false,
                price_role_id: null,
                position: null,
                department_id: null,
                daily_time_goal: null,
                start_date: null,
                employee_number: null
            }
        };
    },
    computed: {
        enforceOAuth2() {
            if (process.env.VUE_APP_ENFORCE_OAUTH2 === "true") {
                return true;
            } else {
                return false;
            }
        }
    },
    async created() {
        await this.fetchRoles();
        await this.fetchCurrencies();
        await this.fetchPriceRoles();
        await this.fetchDepartments(false);

        if (!this.context.empty) {
            this.editing = true;
            this.user = Cloner.pickClone(this.context, [
                "id",
                "name",
                "email",
                "active",
                "acl_role",
                "invite",
                "price_role_id",
                "position",
                "department_id",
                "daily_time_goal",
                "start_date",
                "employee_number"
            ]);
        } else {
            // Creating new user -> invite by default
            this.user.invite = true;
            this.user.daily_time_goal = 26640;
        }
    },
    methods: {
        /**
         * Fetch all price roles
         */
        async fetchPriceRoles(loading = false) {
            try {
                this.loading = true;
                const r = await PriceRoleService.GET_dropdown();
                this.price_roles = r.data.data;
            } catch (e) {
                console.log("fetchPriceRoles exception", e);
            }
            this.loading = loading;
        },
        /**
         * Fetch ACL roles
         */
        async fetchRoles(continueLoading = false) {
            try {
                this.loading = true;
                const r = await UserService.rolesDropdown();
                this.roles = r.data.data;
                this.roles_help = r.data.meta.help;
            } catch (e) {}

            this.loading = continueLoading;
        },
        /**
         * Save form
         */
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await UserService.PUT(this.user);
                } else {
                    const r = await UserService.POST(this.user);
                }

                this.$emit("close-refresh");
            } catch (e) {
                console.log("saveForm exception", e);
                this.errors = e.response.data.errors;
            }
        },
        /**
         * Get currency list
         */
        async fetchCurrencies(loading = true) {
            try {
                this.loading = true;
                const r = await CurrencyService.dropdownCodes({
                    fview: "active"
                });
                this.currencies = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        /**
         * Fetch departments
         */
        async fetchDepartments(loading = true) {
            try {
                this.loading = true;
                const r = await DepartmentService.dropdown();
                this.departments = r.data.data;
            } catch (e) {
                console.log(e);
            }

            this.loading = loading;
        }
    }
};
</script>

<template>
    <modal
        size="lg"
        :loading="loading"
        :show="true"
        @close="$emit('close')"
        class="asset-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("users.edit_coworker") }}
            </template>
            <template v-else>
                {{ $t("users.create_coworker") }}
            </template>
        </template>

        <template slot="default">
            <v-loader :loading="loading" v-if="loading" />
            <FormulateForm
                v-model="user"
                @submit="saveForm"
                :errors="errors"
                v-else
            >
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="name"
                            type="text"
                            :label="$t('users.name')"
                            :validation-name="$t('users.name')"
                            :placeholder="$t('users.name')"
                            validation="required"
                        />
                    </div>
                    <div class="col">
                        <FormulateInput
                            name="email"
                            type="email"
                            :label="$t('users.email')"
                            :validation-name="$t('users.email')"
                            :placeholder="$t('users.email')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="employee_number"
                            type="text"
                            :label="$t('users.employee_number')"
                            :validation-name="$t('users.employee_number')"
                            :placeholder="$t('users.employee_number')"
                        />
                    </div>
                    <div class="col">
                        <FormulateInput
                            name="position"
                            type="text"
                            :label="$t('users.position')"
                            :validation-name="$t('users.position')"
                            :placeholder="$t('users.position')"
                        />
                    </div>
                    <div class="col">
                        <FormulateInput
                            name="department_id"
                            type="select"
                            :label="$t('users.department')"
                            :validation-name="$t('users.department')"
                            :options="departments"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col slider-col">
                        <FormulateInput
                            class="slider-control"
                            name="daily_time_goal"
                            type="range"
                            :label="$t('users.daily_time_goal')"
                            :validation-name="$t('users.daily_time_goal')"
                            validation="required|min:0|max:43200"
                            error-behavior="live"
                            min="0"
                            max="43200"
                            step="60"
                        />
                        <div class="slider-value">
                            {{ $t("users.daily_goal") }}:
                            <template
                                v-if="parseInt(user.daily_time_goal) === 0"
                            >
                                {{
                                    $t(
                                        "users.notice_no_goal_consultant_hourly_paid"
                                    )
                                }}
                            </template>
                            <template v-else>
                                {{ user.daily_time_goal | toTime }}
                            </template>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="acl_role"
                            type="select"
                            :label="$t('users.acl_role')"
                            :validation-name="$t('users.acl_role')"
                            :options="roles"
                            validation="required"
                        />
                        <FormulateInput
                            name="active"
                            type="select"
                            :label="$t('users.is_active')"
                            :validation-name="$t('users.is_active')"
                            :options="mgUsersActive"
                            validation="required"
                        />
                    </div>

                    <div class="col">
                        <label class="form-control-label">{{
                            $t("users.what_can_this_role_do")
                        }}</label>
                        <ul>
                            <li
                                v-for="(el, i) in roles_help[user.acl_role]"
                                :key="i"
                                class="small"
                            >
                                {{ el }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-md-6">
                        <FormulateInput
                            name="price_role_id"
                            type="select"
                            :options="price_roles"
                            :label="$t('users.default_hour_price')"
                            :validation-name="$t('users.default_hour_price')"
                            validation="required"
                        />
                    </div>
                    <div class="col col-md-6">
                        <FormulateInput
                            name="start_date"
                            type="date"
                            :options="start_date"
                            :label="$t('users.start_date')"
                            :validation-name="$t('users.start_date')"
                            :help="$t('users.help_start_date')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            v-if="editing"
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("users.save") }}</base-button
                        >
                        <base-button
                            v-if="!editing"
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{
                               (enforceOAuth2 ? $t("users.save") : $t("users.save_and_send_welcome_email"))
                            }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style lang="scss">
.slider-control {
    margin-bottom: 5px;
}
.slider-col {
}

.slider-value {
    text-align: center;
    margin-bottom: 25px;
}
</style>

<script>
import dayjs from "dayjs";
import InvoiceService from "@/services/InvoiceService";
import MGConsts from "@/mixins/MGConsts";

export default {
    mixins: [MGConsts],
    data() {
        return {
            loading: true,
            elems: [],
            year: 0
        };
    },
    created() {
        this.year = dayjs().format("YYYY");
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await InvoiceService.GET_contractBalances(
                    this.year
                );
                this.elems = data.data;
            } catch (e) {
                console.log("fe", e);
            }

            this.loading = false;
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag> </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <div class="px-4 mb-4">
                            <div class="d-flex align-items-center">
                                <h2>
                                    {{ $t("invoices.balances_for_year") }}:
                                    {{ year }}
                                </h2>
                                <div class="ml-auto">
                                    <a
                                        class="btn btn-primary btn-sm btn-outline"
                                        href="/v1/invoices/contract-balances?csv=1"
                                    >
                                        <i class="fa fa-file-csv"></i>
                                        {{ $t("invoices.export_to_csv") }}</a
                                    >
                                </div>
                            </div>
                            <h4>{{ $t("invoices.explanation") }}</h4>
                            <ul>
                                <li>
                                    <b
                                        >{{
                                            $t(
                                                "invoices.overview_tblhdr_calc_total"
                                            )
                                        }}:</b
                                    >
                                    {{
                                        $t(
                                            "invoices.overview_tblhdr_calc_total_explain"
                                        )
                                    }}
                                </li>
                                <li>
                                    <b
                                        >{{
                                            $t(
                                                "invoices.overview_tblhdr_calc_awaiting"
                                            )
                                        }}:</b
                                    >
                                    {{
                                        $t(
                                            "invoices.overview_tblhdr_calc_awaiting_explain"
                                        )
                                    }}
                                </li>
                                <li>
                                    <b
                                        >{{
                                            $t(
                                                "invoices.overview_tblhdr_calc_invoiced"
                                            )
                                        }}:</b
                                    >
                                    {{
                                        $t(
                                            "invoices.overview_tblhdr_calc_invoiced_explain"
                                        )
                                    }}
                                </li>
                                <li>
                                    <b
                                        >{{
                                            $t(
                                                "invoices.overview_tblhdr_invoiced"
                                            )
                                        }}:</b
                                    >
                                    {{
                                        $t(
                                            "invoices.overview_tblhdr_invoiced_explain"
                                        )
                                    }}
                                </li>
                                <li>
                                    <b
                                        >{{
                                            $t(
                                                "invoices.overview_tblhdr_regulation"
                                            )
                                        }}:</b
                                    >
                                    {{
                                        $t(
                                            "invoices.overview_tblhdr_regulation_explain"
                                        )
                                    }}
                                </li>
                            </ul>
                        </div>
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="elems.length > 0">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-sm">
                                    <v-thead>
                                        <v-tr>
                                            <v-th>{{
                                                $t(
                                                    "invoices.overview_tblhdr_ID"
                                                )
                                            }}</v-th>
                                            <v-th>{{
                                                $t(
                                                    "invoices.overview_tblhdr_customer"
                                                )
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t(
                                                    "invoices.overview_tblhdr_calc_total"
                                                )
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t(
                                                    "invoices.overview_tblhdr_calc_awaiting"
                                                )
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t(
                                                    "invoices.overview_tblhdr_calc_invoiced"
                                                )
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t(
                                                    "invoices.overview_tblhdr_invoiced"
                                                )
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t(
                                                    "invoices.overview_tblhdr_regulation"
                                                )
                                            }}</v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="(elem, index) in elems"
                                            :key="index"
                                        >
                                            <v-td>#{{ elem.contract_id }}</v-td>
                                            <v-td
                                                ><router-link
                                                    :to="{
                                                        name:
                                                            'CustomersTabManageContract',
                                                        params: {
                                                            contract_id:
                                                                elem.contract_id,
                                                            customer_id:
                                                                elem.customer_id
                                                        }
                                                    }"
                                                    >{{ elem.customer_name }}
                                                    <div class="small">
                                                        {{
                                                            mgContractTypeHash[
                                                                elem
                                                                    .contract_type
                                                            ]
                                                        }}
                                                        |
                                                        {{
                                                            elem.asset_type_name
                                                        }}
                                                    </div></router-link
                                                >
                                            </v-td>
                                            <v-td class="text-right">{{
                                                $n(elem.cd_total, {
                                                    style: "currency",
                                                    currency: elem.currency,
                                                    currencyDisplay: "code"
                                                })
                                            }}</v-td>
                                            <v-td class="text-right">{{
                                                $n(elem.cd_awaiting, {
                                                    style: "currency",
                                                    currency: elem.currency,
                                                    currencyDisplay: "code"
                                                })
                                            }}</v-td>
                                            <v-td class="text-right">{{
                                                $n(elem.cd_invoiced, {
                                                    style: "currency",
                                                    currency: elem.currency,
                                                    currencyDisplay: "code"
                                                })
                                            }}</v-td>
                                            <v-td class="text-right">{{
                                                $n(elem.invoiced_amount, {
                                                    style: "currency",
                                                    currency: elem.currency,
                                                    currencyDisplay: "code"
                                                })
                                            }}</v-td>
                                            <v-td class="text-right">{{
                                                $n(elem.regulation, {
                                                    style: "currency",
                                                    currency: elem.currency,
                                                    currencyDisplay: "code"
                                                })
                                            }}</v-td>
                                            <v-td
                                                v-if="false"
                                                class="text-right"
                                                >{{
                                                    $n(elem.cd_reg_staged, {
                                                        style: "currency",
                                                        currency: elem.currency,
                                                        currencyDisplay: "code"
                                                    })
                                                }}</v-td
                                            >
                                            <v-td
                                                v-if="false"
                                                class="text-right"
                                                >{{
                                                    $n(elem.cd_reg_calculated, {
                                                        style: "currency",
                                                        currency: elem.currency,
                                                        currencyDisplay: "code"
                                                    })
                                                }}</v-td
                                            >
                                            <v-td
                                                v-if="false"
                                                class="text-right"
                                                >{{
                                                    $n(elem.cd_reg_invoiced, {
                                                        style: "currency",
                                                        currency: elem.currency,
                                                        currencyDisplay: "code"
                                                    })
                                                }}</v-td
                                            >
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                        </template>
                        <div v-else class="px-4">
                            <div class="alert alert-warning">
                                {{
                                    $t(
                                        "invoices.no_balances_found_for_selected_year"
                                    )
                                }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/libraries/EventBus";
import { mapGetters } from "vuex";

export default {
    props: {
        select: {}
    },
    computed: {
        ...mapGetters("auth", ["getIsAdmin"])
    },
    methods: {
        emitMenu(menu) {
            EventBus.$emit("menu-change", menu);
        }
    }
};
</script>

<template>
    <div id="menu-switcher" v-if="getIsAdmin">
        <button class="btn btn-block btn-primary " @click="emitMenu('default')">
            <i class="far fa-check" v-if="select === 'default'"></i> Group
        </button>
        <button
            class="btn btn-block btn-primary "
            @click="emitMenu('projects')"
        >
            <i class="far fa-check" v-if="select === 'projects'"></i> Projects
        </button>
    </div>
</template>

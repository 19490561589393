var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isClean)?_c('div',[_c('div',{staticClass:"text-center mt-6 alert alert-warning mx-6"},[_c('h1',[_vm._v(_vm._s(_vm.$t("planning.empty_plan_nothing_to_show")))]),_vm._m(0)])]):('errors' in _vm.errors)?_c('div',[_c('div',{staticClass:"mx-6 mt-6 error-box-plan"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_vm._m(1),_c('div',{staticClass:"text-left ml-5"},[_c('ul',{staticClass:"text-bold text-danger"},_vm._l((_vm.errors.errors.data),function(error,index){return _c('li',{key:index},[(Array.isArray(error))?_vm._l((error),function(e,i){return _c('span',[_vm._v(_vm._s(Object.values(e)[0]))])}):[_vm._v(_vm._s(error))]],2)}),0)])])])]):_c('div',{staticClass:"gantt-visualization"},[_c('div',{staticClass:"gantt-controls"},[_c('div',[_c('base-button',{attrs:{"size":"sm","type":"primary"},on:{"click":_vm.emitShowEditing}},[_c('i',{staticClass:"far fa-fw",class:{
                            'fa-chevron-right': !_vm.editing,
                            'fa-chevron-left': _vm.editing
                        }})])],1),_c('div',[_c('base-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.setScaleUnit('week')}}},[_vm._v(_vm._s(_vm.$t("planning.unit_weeks")))]),_c('base-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.setScaleUnit('month')}}},[_vm._v(_vm._s(_vm.$t("planning.unit_months")))]),_c('base-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.setScaleUnit('quarter')}}},[_vm._v(_vm._s(_vm.$t("planning.unit_quarters")))]),_c('base-button',{attrs:{"size":"sm","type":"primary","outline":""},on:{"click":_vm.zoomOut}},[_c('i',{staticClass:"far fa-search-minus fa-fw"})]),_c('base-button',{attrs:{"size":"sm","type":"primary","outline":""},on:{"click":_vm.zoomIn}},[_c('i',{staticClass:"far fa-search-plus fa-fw"})])],1)]),_c('div',{ref:"ganttChartContainer",staticClass:"gantt-chart-container",on:{"mousedown":_vm.onMouseDown,"mousemove":_vm.onMouseMove,"mouseup":_vm.onMouseUp,"mouseleave":_vm.onMouseUp,"touchstart":_vm.onTouchStart,"touchmove":_vm.onTouchMove,"touchend":_vm.onTouchEnd}},[_c('div',{ref:"ganttChart",staticClass:"gantt-chart",style:({
                    width: _vm.totalChartWidth + 'px',
                    height: _vm.totalChartHeight + 'px'
                })},[_c('div',{staticClass:"gantt-header",style:({
                        width: _vm.totalChartWidth + 'px',
                        left: !_vm.editing ? _vm.milestoneNameWidth + 'px' : '20px'
                    })},[_c('div',{staticClass:"gantt-header-row"},_vm._l((_vm.upperScaleUnits),function(unit,index){return _c('div',{key:'upper-' + index,staticClass:"gantt-cell",style:({
                                left: unit.offsetDays * _vm.dayWidth + 'px',
                                width: unit.durationDays * _vm.dayWidth + 'px'
                            })},[_vm._v(" "+_vm._s(unit.label)+" ")])}),0),_c('div',{staticClass:"gantt-header-row"},_vm._l((_vm.lowerScaleUnits),function(unit,index){return _c('div',{key:'lower-' + index,staticClass:"gantt-cell",style:({
                                left: unit.offsetDays * _vm.dayWidth + 'px',
                                width: unit.durationDays * _vm.dayWidth + 'px'
                            })},[_vm._v(" "+_vm._s(unit.label)+" ")])}),0)]),(!_vm.editing)?_c('div',{staticClass:"milestone-names",style:({
                        width: _vm.milestoneNameWidth + 'px',
                        height: _vm.totalChartHeight + 'px'
                    })},_vm._l((_vm.milestones),function(milestone,index){return _c('div',{key:milestone.m_ident,staticClass:"milestone-name",style:({
                            top: _vm.headerHeight + index * _vm.rowHeight + 'px',
                            height: _vm.rowHeight + 'px'
                        })},[_vm._v(" M"+_vm._s(milestone.m_ident)+" ")])}),0):_vm._e(),_c('div',{staticClass:"gantt-bars",style:({
                        left: !_vm.editing ? _vm.milestoneNameWidth + 'px' : '20px',
                        top: _vm.headerHeight + 'px',
                        width: _vm.totalChartWidth + 'px'
                    })},_vm._l((_vm.milestones),function(milestone,index){return _c('div',{key:milestone.m_ident,staticClass:"gantt-bar-c",style:({
                            position: 'absolute',
                            top: index * _vm.rowHeight + 'px',
                            height: _vm.rowHeight + 'px',
                            width: _vm.totalChartWidth + 'px'
                        })},[(
                                milestone.start_date && milestone.end_date
                            )?_c('div',{staticClass:"gantt-bar gantt-bar__main",class:{ 'has-baseline': _vm.showBaseline },style:([
                                _vm.getBarStyle(milestone),
                                { top: '5px' }
                            ]),attrs:{"title":((milestone.name) + ": " + (milestone.start_date) + " -\n                    " + (milestone.end_date))}}):_vm._e(),(
                                _vm.showBaseline &&
                                    _vm.baseline[index]['start_date'] &&
                                    _vm.baseline[index]['end_date']
                            )?_c('div',{staticClass:"gantt-bar gantt-bar__baseline",class:{ 'has-baseline': _vm.showBaseline },style:([
                                _vm.getBarStyle(_vm.baseline[index]),
                                { top: '16px' }
                            ])}):_vm._e()])}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('i',{staticClass:"far fa-star-half-alt fa-5x"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center text-danger"},[_c('i',{staticClass:"far fa-exclamation-triangle fa-4x"})])}]

export { render, staticRenderFns }
<script>
import Cloner from "@/libraries/Cloner";
import VPicker from "./VPicker";
import VDependencyInput from "./VDependencyInput";
import VPadlock from "../VPadlock";

export default {
    props: {
        milestones: {},
        initDna: {
            description:
                "Array for seeding the dna internal array (watcher that triggers update)"
        },
        isRW: {
            type: Boolean,
            default: false,
            description: "Whether editing is allowed"
        },
        errors: {},
        clickedMilestoneId: {}
    },
    components: { VPicker, VDependencyInput, VPadlock },
    data() {
        return {
            loading: true,
            draft: [],
            dna: [],
            isExternalUpdate: false,
            isInitialization: true,
            focus: 0,
            nameVis: {},
            undo: []
        };
    },
    computed: {
        undoSteps() {
            return this.undo.length - 1;
        }
    },
    watch: {
        /**
         * Watch for external updates
         */
        milestones: {
            deep: true,
            handler: function(nV) {
                // console.log("MILE WATCHER");
                this.isExternalUpdate = true;
                this.draft = Cloner.clone(nV);
            }
        },
        initDna: {
            deep: true,
            handler(nV) {
                if (Array.isArray(nV)) {
                    this.dna = Cloner.clone(nV);
                }
            }
        },
        /**
         * Our own updates
         */
        draft: {
            deep: true,
            handler(newDraft) {
                if (!this.isExternalUpdate && !this.isInitialization) {
                    const changedMilestone = newDraft.find(
                        milestone => milestone.m_ident === this.focus
                    );

                    console.log("draftWatcher: m_id = ", changedMilestone);
                    this.focus = 0;

                    if (typeof changedMilestone !== "undefined") {
                        console.log(
                            "Changed Milestone:",
                            changedMilestone.m_ident
                        );
                        this.rerun({ diff: changedMilestone.m_ident });
                    } else {
                        this.rerun();
                    }
                } else {
                    this.isExternalUpdate = false;
                }
            }
        }
    },
    created() {
        this.isExternalUpdate = true;
        this.draft = Cloner.clone(this.milestones);
        this.undo.push({
            initial: true,
            draft: Cloner.clone(this.milestones),
            dna: Cloner.clone(this.dna)
        });
        Object.keys(this.draft).forEach(e => {
            this.$set(this.nameVis, e, false);
        });
        this.isInitialization = false;
        this.loading = false;
    },
    methods: {
        /**
         * Rerun preview
         */
        rerun({ diff = null } = {}) {
            if (!this.validateBeforePreview()) {
                return;
            }
            // Run only dates.

            this.runPreview("dates", diff);
        },
        onFocus(m_ident) {
            this.focus = m_ident;
        },
        onBlur() {
            this.focus = 0;
            this.rerun();
        },
        /**
         * Check if dependency formats are OK.
         */
        validateBeforePreview() {
            for (const el of this.draft) {
                if (!this.checkDependency(el.dependency)) {
                    return false;
                }
            }

            if (this.focus != 0) {
                return false;
            }

            return true;
        },
        /**
         * Validate dependency format
         * @param dependency
         */
        checkDependency(dependency) {
            if (dependency === "" || dependency === null) {
                return true;
            }

            const regex = /^([0-9]+)([SE])([-+]\d+)?$/;
            const matches = dependency.match(regex);

            if (matches) {
                return true;
            } else {
                // Return a default value if no match is found
                return false;
            }
        },
        /**
         * Check if auto-calc conditions are met
         */
        shouldAutoCalc() {
            // only 1 date on start can be set for auto mode.
            let countStart = 0;
            let countEnd = 0;
            this.draft.forEach(elem => {
                if (elem.start_date) {
                    countStart++;
                }
                if (elem.end_date) {
                    countEnd++;
                }
            });

            if (countStart === 1 && countEnd === 0) {
                return true;
            } else {
                return false;
            }
        },
        /**
         * Check if everything is ok
         * @param mode
         */
        runPreview(mode, diff = 0) {
            this.undo.push({
                milestones: this.draft,
                mode: mode,
                dna: this.dna,
                diff: diff
            });
            this.$emit("preview", {
                milestones: this.draft,
                mode: mode,
                dna: this.dna,
                diff: diff
            });
        },
        /**
         * Cleanup all dates
         */
        cleanDates() {
            this.$swal({
                icon: "warning",
                text: this.$t("planning.clean_dates_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    // Clear undo buffer
                    this.undo.splice(1);
                    this.draft.forEach(elem => {
                        elem.start_date = null;
                        elem.end_date = null;
                        elem.lock_start_date = null;
                        elem.lock_end_date = null;
                    });
                }
            });
        },
        applyUndo() {
            /**
             * appending
             * 0 -
             * 1 -
             * 2 -
             */
            const total = this.undo.length;

            if (total - 2 >= 0) {
                const change = this.undo[total - 2];
                if ("initial" in change) {
                    this.undo.splice(1);
                    this.$emit("reset");
                } else {
                    this.undo.splice(total - 2 + 1);
                    this.$emit("preview", change);
                }
            }
        }
    }
};
</script>

<template>
    <div v-if="!loading" class="gantt-editor-container">
        <div class="gantt-editor-container__buttons">
            <div>
                <base-button
                    size="sm"
                    type="danger"
                    outline
                    @click="cleanDates"
                    :disabled="!isRW"
                    ><i class="far fa-trash"></i
                ></base-button>
                <base-button
                    v-if="isRW"
                    size="sm"
                    type="warning"
                    outline
                    @click="applyUndo"
                    :disabled="undoSteps == 0"
                    ><i class="far fa-undo"></i> {{ undoSteps }}</base-button
                >

                <template v-if="false">F: {{ focus }}</template>
            </div>
            <div>
                <span class="style-master">
                    {{ $t("planning.style_editing") }}</span
                >
                <span class="style-baseline">
                    {{ $t("planning.style_baseline") }}</span
                >
            </div>
        </div>
        <div class="cont">R{{ clickedMilestoneId }}
            <div class="editor">
                <div class="editor__id"></div>

                <div class="editor__inp">
                    {{ $t("planning.dates") }}
                </div>
                <div class="editor__dep">
                    {{ $t("planning.dep") }}
                </div>
            </div>
            <div
                v-for="(m, k) in draft"
                :key="m.id"
                class="editor"
                :class="{
                    'has-error': 'errors' in errors,
                    'is-clicked': clickedMilestoneId == m.id
                }"
            >
                <div
                    class="editor__id"
                    @mouseenter="nameVis[k] = true"
                    @mouseleave="nameVis[k] = false"
                >
                    M{{ m.m_ident }}
                </div>

                <div class="editor__name" v-show="nameVis[k]">
                    <div class="editor__iname">{{ m.name }}</div>
                </div>

                <div class="editor__inp" v-show="!nameVis[k]">
                    <div>
                        <v-picker
                            v-model="m.start_date"
                            type="start"
                            :duration="m.duration"
                            :dependent="m.end_date"
                            :disabled="!isRW"
                            @update-dependent="
                                m.lock_end_date != 1 && (m.end_date = $event)
                            "
                            @focus="onFocus(m.m_ident)"
                        />
                        <v-padlock
                            class="ml-1"
                            v-model="m.lock_start_date"
                            :false-value="null"
                            :disabled="!isRW"
                            @click="onFocus(0)"
                        />
                    </div>
                    <div>
                        <v-picker
                            v-model="m.end_date"
                            type="end"
                            :duration="m.duration"
                            :dependent="m.start_date"
                            :disabled="!isRW"
                            @update-dependent="
                                m.lock_start_date != 1 &&
                                    (m.start_date = $event)
                            "
                            @focus="onFocus(m.m_ident)"
                        />
                        <v-padlock
                            class="ml-1"
                            v-model="m.lock_end_date"
                            :false-value="null"
                            :disabled="!isRW"
                            @click="onFocus(0)"
                        />
                    </div>
                </div>

                <div class="editor__dep" v-show="!nameVis[k]">
                    <v-dependency-input
                        v-model.lazy="m.dependency"
                        @focus="onFocus(m.m_ident)"
                        @blur="onBlur"
                        :disabled="!isRW"
                    />

                    <v-padlock
                        class="ml-1"
                        v-model="dna"
                        :true-value="m.m_ident"
                        :false-value="null"
                        :disabled="!isRW"
                        @click="onFocus(0)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.gantt-editor-container {
    &__buttons {
        padding-right: 20px;
        display: flex;
        justify-content: space-between;
        /* Justifying heights */
        margin-bottom: 20px;
    }
}
.editor.has-error {
    input {
        border: 1px solid red !important;
    }
    .editor__id {
        color: red !important;
    }
}

.cont {
    .form-group {
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 0px;
        margin-bottom: 0px;
        input.form-control {
            height: 25px !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.style-baseline {
    background: $gray-300;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
    color: black;
    font-weight: bold;
    font-size: 13px;
    margin-left: 8px;
}

.style-master {
    background: $default;
    opacity: 0.6;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 13px;
}

.cont {
    border-right: 5px solid $gray-600;

    .editor {
        font-size: 12px;
        border-bottom: 1px dotted $gray-600;
        padding: 5px;
        line-height: 20px; /* Align text vertically */
        display: flex;

        &.is-clicked {
            background: $gray-300;
        }

        &__name {
            height: 24px;
            position: relative;
        }

        &__iname {
            position: absolute;
            background: white;
            padding-left: 15px;
            z-index: 9999;
            width: 500px;
            font-weight: bold;
            padding-top: 2px;
            padding-bottom: 2px;
        }

        &__id {
            font-weight: 600;
            flex: 0 0 40px;
            text-align: center;
        }

        &__inp {
            display: flex;
            justify-content: space-evenly;
            flex: 1 1;
            flex-direction: row;
        }

        &__dep {
            text-align: center;
            flex: 0 0 105px;
            input[type="text"] {
                width: 46px;
            }
        }
    }
}
</style>

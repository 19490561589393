<script>
export default {
    props: {
        availableYears: {},
        displayYear: {},
        meta: {},
        contract: {}
    },
    data() {
        return {
            intYear: ""
        };
    },
    created() {
        this.intYear = this.displayYear;
        this.$watch("intYear", this.changeYear);
    },
    methods: {
        changeYear(nV) {
            this.$emit("change-year", nV);
        }
    }
};
</script>

<template>
    <div>
        <h3 class="d-flex align-items-center mb-4">
            <div class="mr-3">
                {{ $t("contract.show_data_for") }}
            </div>
            <FormulateInput
                type="select"
                :options="availableYears"
                v-model="intYear"
                :value="displayYear"
            />
        </h3>

        <div>
            {{ $t("contract.total_calculated_for_all_periods") }}:
            <span
                :class="{
                    'text-danger': meta.details.has_staged
                }"
                ><i
                    v-if="meta.details.has_staged"
                    class="far fa-exclamation-triangle"
                ></i>
                {{
                    $n(meta.details.sum, {
                        style: "currency",
                        currency: contract.currency,
                        currencyDisplay: "code"
                    })
                }}</span
            >
            <div class="text-danger" v-if="meta.details.has_staged">
                <i class="far fa-exclamation-triangle"></i>
                {{ $t("contract.WARNING_includes_staged_periods") }}
            </div>
        </div>
    </div>
</template>

<style scoped>
.form-group {
    width: 140px;
    margin-bottom: 0px !important;
}
</style>

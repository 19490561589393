<script>
import VListFlags from "./VListFlags";
import VStatusMilestone from "@/components/Planning/Helpers/VStatusMilestone";
import AppCfg from "@/mixins/AppCfg";
import MGConsts from "@/mixins/MGConsts";
import {
    FlagStatus,
    FlagTypes,
    FlagTypesDetailed,
    FlagTypesIcons,
    FlagSubtypes
} from "@/constants/Planning.const";
import VTimelineEditForm from "@/components/Forms/VTimelineEditForm";

export default {
    props: {
        flags: {},
        title: {},
        alarm: {
            type: String,
            default: "green"
        },
        normal: {
            type: Boolean,
            default: false
        }
    },
    mixins: [AppCfg, MGConsts],
    components: { VListFlags, VStatusMilestone, VTimelineEditForm },
    data() {
        return {
            showEditTimeline: false,
            timelineContext: null
        };
    },
    computed: {
        listFlags() {
            return FlagTypes;
        },
        flagTypes() {
            return this.$hash2select(FlagTypes);
        },
        flagDetails() {
            return FlagTypesDetailed;
        },
        flagIcons() {
            return FlagTypesIcons;
        },
        flagStatus() {
            return FlagStatus;
        },
        flagSubtypes() {
            return FlagSubtypes;
        }
    },
    methods: {
        showTimeline(project_id) {
            this.timelineContext = project_id;
            this.showEditTimeline = true;
        }
    }
};
</script>

<template>
    <div class="v-project-report-group">
        <card>
            <h2 class="mb-3">
                <i class="fas fa-flag" :class="`flag-${alarm}`"></i> {{ title }}
            </h2>
            <template v-if="flags.length > 0">
                <v-table class="table table-sm table-bordered table__flag">
                    <v-thead>
                        <v-tr>
                            <v-th>{{ $t("flag_report.hdr_project") }}</v-th>
                            <v-th>{{ $t("flag_report.hdr_type") }}</v-th>
                            <v-th>{{ $t("flag_report.hdr_milestone") }}</v-th>
                            <v-th>{{ $t("flag_report.hdr_fsubtype") }}</v-th>
                            <v-th>{{ $t("flag_report.hdr_category") }}</v-th>
                            <v-th>{{ $t("flag_report.hdr_impact") }}</v-th>
                            <v-th>{{ $t("flag_report.hdr_mitigation") }}</v-th>
                        </v-tr>
                    </v-thead>
                    <v-tbody>
                        <v-tr v-for="f in flags" :key="f.id">
                            <template v-if="normal">
                                <v-td class="table__flag_title"
                                    ><router-link
                                        :to="{
                                            name: 'ProjectDisplay',
                                            params: { project_id: f.id }
                                        }"
                                        >{{ f.title }}</router-link
                                    ></v-td
                                >
                                <v-td class="table__flag_type">{{
                                    f.project_type_name
                                }}</v-td>
                                <v-td class="text-center table__flag_milestone"
                                    ><v-status-milestone
                                        v-if="
                                            Array.isArray(
                                                f.project_milestones
                                            ) && f.project_milestones.length > 0
                                        "
                                        :milestone="f.project_milestones[0]"
                                    />
                                    <template v-else>---</template></v-td
                                >
                                <v-td class="table__flag_subtype">---</v-td>
                                <v-td class="table__flag_category">---</v-td>
                                <v-td class="table__flag_impact">---</v-td>
                                <v-td class="table__flag_how">---</v-td>
                            </template>
                            <template v-else>
                                <v-td class="table__flag_title"
                                    ><router-link
                                        :to="{
                                            name: 'ProjectDisplay',
                                            params: { project_id: f.project.id }
                                        }"
                                        >{{ f.project.title }}</router-link
                                    ></v-td
                                >
                                <v-td class="table__flag_type">{{
                                    f.project.project_type_name
                                }}</v-td>
                                <v-td class="text-center table__flag_milestone"
                                    ><v-status-milestone
                                        v-if="
                                            Array.isArray(
                                                f.project.project_milestones
                                            ) &&
                                                f.project.project_milestones
                                                    .length > 0
                                        "
                                        :milestone="
                                            f.project.project_milestones[0]
                                        "
                                    />
                                    <template v-else>---</template></v-td
                                >
                                <v-td class="table__flag_subtype"
                                    >{{ flagSubtypes[f.subtype] }}

                                    <div
                                        class="small"
                                        v-if="f.subtype == 'timeline_change'"
                                    >
                                        <span
                                            class="m-click text-primary"
                                            @click="showTimeline(f.project_id)"
                                            ><i class="far fa-search"></i>
                                            {{
                                                $t("flag_report.show_details")
                                            }}</span
                                        >
                                    </div>
                                    <div class="small text-muted mt-2">
                                        {{ $t("flag_report.created") }}:
                                        {{ $d(new Date(f.created), "short") }}
                                    </div>
                                    <div class="small text-muted" v-if="false">
                                        {{ flagStatus[f.status] }}
                                    </div></v-td
                                >
                                <v-td class="table__flag_category">
                                    <template
                                        v-if="f.subtype == 'timeline_change'"
                                        >---</template
                                    >
                                    <template v-else>{{
                                        f.flag_category.name
                                    }}</template>
                                </v-td>

                                <v-td class="table__flag_impact"
                                    ><v-newlines :text="f.description"
                                /></v-td>
                                <v-td class="table__flag_how"
                                    ><v-newlines :text="f.description2"
                                /></v-td>
                            </template>
                        </v-tr>
                    </v-tbody>
                </v-table>
            </template>
            <div class="alert alert-outline alert-warning" v-else>
                {{ $t("project.group_no_projects_in_this_group") }}
            </div>
        </card>
        <portal to="modals">
            <v-timeline-edit-form
                v-if="showEditTimeline"
                :project-id="timelineContext"
                :force-ro="true"
                @close="showEditTimeline = false"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.v-project-report-group {
    .table__flag {
        td {
            text-wrap: wrap;
        }

        &_title {
        }
        &_type {
            width: 90px;
            text-align: center;
        }
        &_milestone {
            width: 60px;
        }
        &_subtype {
            width: 170px;
        }
        &_category {
            width: 160px;
        }
        &_created {
            width: 120px;
        }
        &_impact {
            width: 240px;
        }
        &_how {
            width: 240px;
        }
    }

    .flag {
        &-green {
            color: $success;
        }

        &-orange {
            color: $warning;
        }

        &-red {
            color: $danger;
        }
    }
}
</style>

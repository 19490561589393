<script>
import VoucherService from "@/services/VoucherService";

export default {
    props: {
        context: {}
    },
    data() {
        return {
            loading: true
        }
    },
    computed: {
        pdfUrl() {
            return `/v1/vouchers/fetch-doc-pdf/${this.context.id}`;
        }
    },    
};
</script>

<template>
    <modal
        size="full"
        :show="true"
        @close="$emit('close')"
        class="budget-modal"
    >
        <template slot="header">{{$t('voucher.view_document')}}</template>
        <template>
            <div class="row voucher-data">
                <div class="col">
                    <div class="small text-muted">
                        {{ $t("vouchers.voucher_note") }}
                    </div>
                    {{ context.doc_note }}
                </div>
                <div class="col">
                    <div class="small text-muted">
                        {{ $t("vouchers.title") }}
                    </div>
                    {{ context.description }}
                </div>
                <div class="col-2">
                    <div class="small text-muted">
                        {{ $t("vouchers.date_and_yax_year") }}
                    </div>
                    {{ context.voucher_date }}
                    <div class="text-info small">
                        {{ $t("vouchers.tax_year") }}:
                        {{ context.voucher_tax_year }}
                    </div>
                </div>
                <div class="col-2">
                    <div class="small text-muted">
                        {{ $t("vouchers.ec_number") }}
                    </div>
                    {{ context.ec_voucher_number }}
                </div>
            </div>

            <v-loader :loading="loading" v-if="loading" />
            <iframe id="pdfViewer" :src="pdfUrl" frameborder="0"  @load="loading = false" ></iframe>
        </template>
    </modal>
</template>

<style lang="scss">
#pdfViewer {
    width: 100%;
    height: 100%;
    border: none; /* No border around the iframe */
}
</style>

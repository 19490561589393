<script>
import Cloner from "@/libraries/Cloner";
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import { EventBus } from "@/libraries/EventBus";
import { mapGetters } from "vuex";

export default {
    props: {
        context: {}
    },
    data() {
        return {
            saving: false,
            errors: {},
            entity: {
                id: null,
                status: "",
                done_note: ""
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        canEdit() {
            if (
                parseInt(this.getMyId) ===
                parseInt(this.context.done_init_user_id)
            ) {
                return true;
            } else {
                return false;
            }
        }
    },
    created() {
        this.entity = Cloner.pickClone(this.context, [
            "id",
            "status",
            "done_note"
        ]);
    },
    methods: {
        async saveForm() {
            try {
                this.saving = true;
                const {
                    data
                } = await ProjectMilestoneService.PUT_createStatusChangeRequest(
                    this.entity
                );
                EventBus.$emit("planning-refresh");
                this.$emit("close");
                this.$notify({
                    message: this.$t("project_milestone.notify_data_saved"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success"
                });
            } catch (e) {
                console.log("save e", e);
            }

            this.saving = false;
        },
        async confirmRequest() {
            try {
                this.saving = true;
                const {
                    data
                } = await ProjectMilestoneService.PUT_confirmStatusChangeRequest(
                    this.entity.id
                );
                EventBus.$emit("planning-refresh");
                this.$notify({
                    message: this.$t(
                        "project_milestone.notify_request_confirmed"
                    ),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success"
                });
            } catch (e) {
                console.log("configm e", e);
            }
        },
        async handleDelete() {
            this.$swal({
                icon: "warning",
                text: this.$t(
                    "project_milestone.delete_status_change_request_are_you_sure"
                ),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await ProjectMilestoneService.PUT_resetStatusChangeRequest(
                        this.entity.id
                    );
                    EventBus.$emit("planning-refresh");
                    this.$emit('close');
                    this.$notify({
                        message: this.$t(
                            "project_milestone.notify_change_request_deleted"
                        ),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "success"
                    });
                }
            });
        }
    }
};
</script>

<template>
    <modal size="xl" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            {{ $t("project_milestone.change_status") }}
        </template>

        <template slot="default">
            <template v-if="context.status == 0">
                <h3>
                    {{
                        $t(
                            "project_milestone.hdr_change_status_to_done(getconfirmation)"
                        )
                    }}
                </h3>
                <FormulateForm
                    v-model="entity"
                    @submit="saveForm"
                    :errors="errors"
                >
                    <div class="row">
                        <div class="col-12">
                            <FormulateInput
                                name="done_note"
                                type="textarea"
                                :placeholder="
                                    $t(
                                        'project_milestone.reasoning_placeholder'
                                    )
                                "
                                :help="$t('project_milestone.reasoning_help')"
                                :label="$t('project_milestone.reasoning')"
                                validation="required"
                                rows="10"
                            />
                        </div>
                    </div>
                    <div class="text-right mt-4">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="saving"
                            native-type="submit"
                            >{{ $t("project_milestone.request") }}</base-button
                        >
                    </div>
                </FormulateForm>
            </template>
            <template v-else-if="context.status == 1">
                <h3 class="text-warning">
                    {{ $t("project_milestone.hdr_awaiting_confirmation") }}
                </h3>

                <dl>
                    <dt>{{ $t("project_milestone.request_created") }}</dt>
                    <dd>
                        {{ $d(new Date(context.done_initiated_date)) }}
                        {{ $t("project_milestone.by") }}
                        {{ context.init_user.name }}
                    </dd>
                </dl>

                <FormulateForm
                    v-model="entity"
                    @submit="saveForm"
                    :errors="errors"
                >
                    <div class="row">
                        <div class="col-12">
                            <FormulateInput
                                name="done_note"
                                type="textarea"
                                :disabled="!canEdit"
                                :placeholder="
                                    $t(
                                        'project_milestone.reasoning_placeholder'
                                    )
                                "
                                :help="
                                    $t(
                                        'project_milestone.reasoning_awaiting_help'
                                    )
                                "
                                :label="$t('project_milestone.reasoning')"
                                validation="required"
                                rows="10"
                            />
                        </div>
                    </div>
                    <div class="row" v-if="canEdit">
                        <div class="col">
                            <base-button type="danger" @click="handleDelete"
                                ><i class="far fa-trash"></i>
                                {{ $t("delete") }}</base-button
                            >
                        </div>
                        <div class="col text-right">
                            <base-button
                                type="secondary"
                                :outline="true"
                                @click="$emit('close')"
                                >{{ $t("cancel") }}</base-button
                            >
                            <base-button type="primary" native-type="submit">{{
                                $t("save")
                            }}</base-button>
                        </div>
                    </div>
                    <div class="text-right mt-4"></div>
                </FormulateForm>
            </template>
            <template v-else-if="context.status == 5">
                <h3 class="text-success">
                    {{ $t("project_milestone.hdr_status_change_confirmed") }}
                </h3>
                <div class="row">
                    <div class="col">
                        <dl>
                            <dt>
                                {{ $t("project_milestone.request_created") }}
                            </dt>
                            <dd>
                                {{ $d(new Date(context.done_initiated_date)) }}
                                {{ $t("project_milestone.by") }}
                                {{ context.init_user.name }}
                            </dd>
                        </dl>
                    </div>
                    <div class="col">
                        <dl>
                            <dt>
                                {{ $t("project_milestone.request_confirmed") }}
                            </dt>
                            <dd>
                                {{ $d(new Date(context.done_confirmed_date)) }}
                                {{ $t("project_milestone.by") }}
                                {{ context.confirmed_user.name }}
                            </dd>
                        </dl>
                    </div>
                </div>

                <dl>
                    <dt>{{ $t("project_milestone.reasoning") }}</dt>
                    <dd><v-newlines :text="context.done_note" /></dd>
                </dl>
            </template>
            <pre v-if="false">{{ context }}</pre>
        </template>
    </modal>
</template>

<script>
export default {
    props: {
        value: {},
        trueValue: {
            default: 1
        },
        falseValue: {
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isArray() {
            return Array.isArray(this.value);
        },
        isChecked() {
            // If the value is an array, check for inclusion, else direct comparison
            return this.isArray
                ? this.value.includes(this.trueValue)
                : this.value === this.trueValue;
        }
    },
    methods: {
        emitValue() {
            if (this.disabled) {
                return;
            }
            this.$emit('click');
            if (this.isArray) {
                if (this.isChecked) {
                    // Remove item from array
                    const newValue = this.value.filter(
                        val => val !== this.trueValue
                    );
                    this.$emit("input", newValue);
                } else {
                    // Add item to array
                    const newValue = [...this.value, this.trueValue];
                    this.$emit("input", newValue);
                }
            } else {
                if (this.isChecked) {
                    this.$emit("input", this.falseValue);
                } else {
                    this.$emit("input", this.trueValue);
                }
            }
        }
    }
};
</script>

<template>
    <i
        @click="emitValue"
        class="far fa-fw"
        :class="{
            'm-disabled': disabled,
            'm-click': !disabled,
            'fa-lock text-warning': isChecked,
            'fa-lock-open text-muted': !isChecked
        }"
    ></i>
</template>

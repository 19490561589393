<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
    data() {
        return {
            loading: false,
            error: false,
            model: {
                email: "",
                password: "",
                rememberMe: false
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["isAuthenticated", "getUserLocale"]),
        enableOAuth2() {
            if (process.env.VUE_APP_ENABLE_OAUTH2 === "true") {
                return true;
            } else {
                return false;
            }
        },
        enforceOAuth2() {
            if (process.env.VUE_APP_ENFORCE_OAUTH2 === "true") {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        ...mapActions("auth", ["authenticate"]),
        ...mapActions("backend", ["fetchConstants"]),
        async onSubmit() {
            try {
                const r = await this.authenticate({
                    username: this.model.email,
                    password: this.model.password
                });

                if (this.isAuthenticated) {
                    console.log("Locale change", this.getUserLocale);
                    // Set locale
                    // Fetch constants
                    await this.fetchConstants();
                    this.$i18n.locale = this.getUserLocale;

                    this.$router.push("/start");
                } else {
                    this.error = true;
                }
            } catch (e) {
                console.log("Login e", e);
                this.error = true;
            }
        },
        initOAuth2() {
            window.location.href = "/v1/oauth2-callback";
        }
    }
};
</script>

<template>
    <div>
        <!-- Header -->
        <div class="header py-5">
            <div class="container">
                <div class="header-body text-center mb-2">
                    <div class="px-5 logo">
                        <img src="/img/logo/meg-logo-white.png" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Page content -->
        <div class="container pb-5">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7">
                    <div class="card bg-secondary border-0 mb-0">
                        <div
                            class="card-header bg-transparent text-center mt-2"
                        >
                            {{ $t("login.please_sign_in") }}
                            <div class="justify-content-center flavor-title">
                                Momentum Energy Group
                            </div>
                        </div>
                        <div
                            class="card-body px-lg-5 py-lg-5"
                            v-if="enforceOAuth2"
                        >
                            <div class="text-center mt-2" v-if="enableOAuth2">
                                <button
                                    type="button"
                                    @click="initOAuth2"
                                    class="btn btn-microsoft btn-icon btn-white"
                                >
                                    <span class="btn-inner--icon"
                                        ><img src="/img/logo/office365.png"
                                    /></span>
                                    <span class="btn-inner--text">{{
                                        $t(
                                            "login.login_with_microsoft_mg_account"
                                        )
                                    }}</span>
                                </button>
                            </div>
                        </div>
                        <div class="card-body px-lg-5 py-lg-5" v-else>
                            <div class="alert alert-danger" v-if="error">
                                {{ $t("wrong_username_or_password") }}
                            </div>

                            <validation-observer
                                v-slot="{ handleSubmit }"
                                ref="formValidator"
                            >
                                <form
                                    role="form"
                                    @submit.prevent="handleSubmit(onSubmit)"
                                >
                                    <base-input
                                        alternative
                                        class="mb-3"
                                        name="Email"
                                        :rules="{ required: true }"
                                        prepend-icon="ni ni-email-83"
                                        :placeholder="$t('login.email')"
                                        v-model="model.email"
                                    >
                                    </base-input>

                                    <base-input
                                        alternative
                                        class="mb-3"
                                        name="Password"
                                        :rules="{ required: true, min: 6 }"
                                        prepend-icon="ni ni-lock-circle-open"
                                        type="password"
                                        :placeholder="$t('login.password')"
                                        v-model="model.password"
                                    >
                                    </base-input>

                                    <!--
                                    <base-checkbox v-model="model.rememberMe"
                                        >Remember me</base-checkbox
                                    >
                                    -->
                                    <div class="text-center">
                                        <base-button
                                            type="primary"
                                            native-type="submit"
                                            class="my-4"
                                            >{{
                                                $t("login.sign_in")
                                            }}</base-button
                                        >
                                    </div>
                                    <div
                                        class="text-center mt-2"
                                        v-if="enableOAuth2"
                                    >
                                        <button
                                            type="button"
                                            @click="initOAuth2"
                                            class="btn btn-microsoft btn-icon btn-white"
                                        >
                                            <span class="btn-inner--icon"
                                                ><img
                                                    src="/img/logo/office365.png"
                                            /></span>
                                            <span class="btn-inner--text">{{
                                                $t(
                                                    "login.login_with_microsoft_mg_account"
                                                )
                                            }}</span>
                                        </button>
                                    </div>
                                </form>
                            </validation-observer>
                        </div>
                    </div>

                    <div class="row mt-3" v-if="!enforceOAuth2">
                        <div class="col-12 text-center">
                            <router-link
                                to="/forgot-password"
                                class="text-light"
                                ><small>{{
                                    $t("login.forgot_password")
                                }}</small></router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.flavor-title {
    //color: white;
    font-weight: bold;
    font-size: 24px;
}
.logo {
    img {
        max-width: 190px;
    }

    @media (max-width: 580px) {
        img {
            max-width: 200px;
        }
    }
}

.btn-microsoft {
    border-color: $border-color;

    &:hover {
        border-color: $primary;
    }
}

.input-group-alternative {
    border: 1px solid $border-color;
    box-shadow: none;

    &:hover,
    &:focus-within {
        box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
        border-color: $primary;
    }
}

.card {
    &-header {
        border-bottom: none;
    }

    &-body {
        padding-top: 20px !important;
    }
}
</style>

import i18n from "@/i18n";

const VoucherStatus = {
    0: i18n.t("vouchers.status_ignored"),
    1: i18n.t("vouchers.status_imported"),
    //5: i18n.t("vouchers.status_unbalanced"),
    10: i18n.t("vouchers.status_booked")
};

export { VoucherStatus };

<script>
/**
 * ProjectLinks
 */
import Cloner from "@/libraries/Cloner";
import ProjectLinkService from "@/services/ProjectLinkService";
import { mapGetters } from "vuex";
import { LinkMetas } from "@/constants/Planning.const";

export default {
    props: {
        context: {},
        projectId: {}
    },
    data() {
        return {
            saving: false,
            editing: false,
            loading: true,
            errors: {},
            entity: {
                id: null,
                project_id: null,
                title: null,
                url: null,
                user_id: null,
                meta: ""
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        canDelete() {
            if (this.editing && this.context.user_id === this.getMyId) {
                return true;
            } else {
                return false;
            }
        },
        metas() {
            let arr = [];

            for (let k in LinkMetas) {
                arr.push({
                    label: LinkMetas[k],
                    value: k
                });
            }

            return arr;
        }
    },
    created() {
        if (this.context.empty) {
            this.editing = false;
        } else {
            this.editing = true;
            this.entity = Cloner.pickClone(this.context, [
                "id",
                "title",
                "meta",
                "url"
            ]);
        }

        this.entity.project_id = this.projectId;
    },
    methods: {
        async saveForm() {
            try {
                this.saving = true;
                if (this.editing) {
                    await ProjectLinkService.PUT(this.entity);
                } else {
                    await ProjectLinkService.POST(this.entity);
                }

                this.$emit("close-reload");
            } catch (e) {
                this.errors = e.response.data.errors;
                console.log("save e ", e);
            }

            this.saving = false;
        },
        async deleteEntity() {
            this.$swal({
                icon: "warning",
                text: this.$t("project_links.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        this.$notify({
                            message: this.$t(
                                "project_links.notify_journal_deleted"
                            ),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });
                        await ProjectLinkService.DELETE(this.context.id);

                        this.$emit("close-reload");
                        // Just remove - no need to reload
                    } catch (e) {}
                }
            });
        }
    }
};
</script>

<template>
    <modal size="lg" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            <template v-if="editing">{{
                $t("project_links.edit_link")
            }}</template>
            <template v-else>{{ $t("project_links.create_link") }}</template>
        </template>

        <template slot="default">
            <FormulateForm v-model="entity" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            name="title"
                            type="text"
                            :placeholder="$t('project_links.title')"
                            :label="$t('project_links.title')"
                            rows="10"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="url"
                            type="textarea"
                            :placeholder="$t('project_links.url_placeholder')"
                            :label="$t('project_links.url')"
                            rows="10"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="meta"
                            type="select"
                            :label="$t('project_links.meta')"
                            :options="metas"
                            :help="$t('project_links.help_meta')"
                        />
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col">
                        <base-button
                            type="danger"
                            :outline="true"
                            @click="deleteEntity"
                            ><i class="far fa-trash"></i>
                            {{ $t("delete") }}</base-button
                        >
                    </div>
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="saving"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<script>
/**
 * In customer = list of all contracts
 */
import ContractCondition from "@/components/Momentum/Contracts/ContractCondition";
import ContractStatus from "@/components/Momentum/Contracts/ContractStatus";
import VTurnover from "@/components/Momentum/Contracts/VTurnover";
import VContractForm from "@/components/Forms/VContractForm";
import ContractService from "@/services/ContractService";

export default {
    name: "VContracts",
    components: {
        ContractCondition,
        ContractStatus,
        VTurnover,
        VContractForm
    },
    props: {
        customer_id: {
            description: "Customer ID"
        }
    },
    data() {
        return {
            loading: false,
            contracts: [],
            editing: false,
            editContext: null,
            editMethod: ""
        };
    },
    computed: {
        /**
         *
         */
        hasActiveCase() {
            if (
                this.contract.is_terminated &&
                parseInt(this.contract.project_id) > 0 &&
                parseInt(this.contract.project.status) === 5
            ) {
                return true;
            }

            return false;
        }
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        await this.fetchContracts();
    },
    methods: {
        async fetchContracts() {
            try {
                this.loading = true;
                const r = await ContractService.GET_AllForCustomer({
                    customer_id: this.customer_id
                });
                this.contracts = r.data.data;
            } catch (e) {}
            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx, editMethod) {
            this.editContext = ctx;
            this.editing = true;
            this.editMethod = editMethod;
        },
        /**
         * Finish
         */
        async closeSave() {
            this.editing = false;
            await this.fetchContracts();
        }
    }
};
</script>

<template>
    <div class="container-fluid">
        <div class="row card-wrapper">
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left">
                        <h1>{{ $t("contracts.contracts") }}</h1>
                    </div>
                    <div class="mg-title__right">
                        <base-button
                            v-if="ACL_RW"
                            size="sm"
                            type="secondary"
                            @click="setContext({ empty: true })"
                            ><i class="far fa-plus"></i>
                            {{ $t("contracts.add_contract") }}</base-button
                        >
                    </div>
                </div>

                <v-loader :loading="loading" v-if="loading" />

                <template v-else-if="contracts.length">
                    <card
                        class="contract"
                        body-classes="px-0"
                        mini-header-classes="text-center"
                    >
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <tr>
                                    <th>{{ $t("contract.hdr_ID") }}</th>
                                    <th>
                                        {{ $t("contract.hdr_status") }}
                                    </th>
                                    <th>
                                        {{ $t("contract.hdr_contract_type") }}
                                    </th>
                                    <th>
                                        {{
                                            $t(
                                                "contract.hdr_non_termination_dates"
                                            )
                                        }}
                                    </th>
                                    <th>
                                        {{ $t("contract.hdr_finance_user") }}
                                    </th>
                                    <th>
                                        {{ $t("contract.hdr_operations_user") }}
                                    </th>
                                    <th>{{ $t("contract.hdr_links") }}</th>
                                    <th></th>
                                </tr>
                            </v-thead>

                            <v-tbody>
                                <v-tr v-for="c in contracts" :key="c.id">
                                    <v-td>#{{ c.id }}</v-td>
                                    <v-td>
                                        <contract-status :contract="c" />
                                    </v-td>

                                    <v-td>
                                        <i
                                            :class="`far ${c.asset_type.icon}`"
                                        ></i>
                                        {{ c.contract_type_text }}
                                        <contract-condition
                                            :contract="c"
                                            :condition="c.current_condition"
                                        />
                                    </v-td>

                                    <v-td>
                                        {{ $d(new Date(c.from_date), "short") }}
                                        - {{ $d(new Date(c.to_date), "short") }}
                                    </v-td>

                                    <v-td>
                                        {{ c.finance_user.name }}
                                    </v-td>
                                    <v-td>
                                        {{ c.operations_user.name }}
                                    </v-td>
                                    <v-td>
                                        <span
                                            class="a-click"
                                            :title="
                                                $t(
                                                    'contract.go_to_connected_project'
                                                )
                                            "
                                            v-if="c.project_id > 0"
                                            @click="
                                                $router.push({
                                                    name: 'ProjectDisplay',
                                                    params: {
                                                        project_id: c.project_id
                                                    }
                                                })
                                            "
                                        >
                                            <i
                                                class="far fa-clipboard-list-check"
                                            ></i>
                                        </span>

                                        <a
                                            target="_blank"
                                            class="a-click"
                                            v-if="c.sharepoint_url"
                                            :href="c.sharepoint_url"
                                            ><i class="far fa-file-pdf ml-2"></i
                                        ></a>
                                    </v-td>
                                    <v-td>
                                        <router-link
                                            class="a-click"
                                            :to="{
                                                name:
                                                    'CustomersTabManageContract',
                                                params: { contract_id: c.id }
                                            }"
                                        >
                                            <i class="far fa-search-plus"></i>
                                        </router-link>
                                    </v-td>
                                </v-tr>
                            </v-tbody>
                        </v-table>
                        <pre v-if="false">{{ contracts }}</pre>
                    </card>
                </template>

                <card body-classes="px-0" v-else>
                    <div class="pb-mg">
                        <div class="nice-info">
                            {{ $t("contract.no_contracts") }}
                        </div>
                    </div>
                </card>
            </div>
        </div>
        <!-- .row for contracts -->

        <div class="row card-wrapper" v-if="false">
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left">
                        <h1>{{ $t("additional_turnover") }}</h1>
                    </div>
                    <div class="mg-title__right"></div>
                </div>

                <v-turnover :customer_id="customer_id" />
            </div>
        </div>
        <!-- .row for additional turnover -->

        <v-contract-form
            v-if="editing"
            :customer_id="customer_id"
            :context="editContext"
            :method="editMethod"
            @close="editing = false"
            @close-refresh="closeSave"
        />
    </div>
</template>

import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const vouchersMenu = {
    path: "/vouchers",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.vouchers")
    },
    children: [
        {
            path: "index",
            name: "VouchersIndex",
            component: () =>
                import(/* webpackChunkName: "vouchers" */ "@/views/Vouchers/VCostsGlobal"),
            meta: {
                title: i18n.t("title.vouchers_global_booked")
            }
        },
        {
            path: "inbox",
            name: "VouchersInbox",
            component: () =>
                import(/* webpackChunkName: "vouchers" */ "@/views/Vouchers/VCostsInboxGlobal"),
            meta: {
                title: i18n.t("title.vouchers_global_inbox")
            }
        }
    ]
};

export default vouchersMenu;
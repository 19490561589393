<script>
/**
 * Report: flagging
 */
import VProjectReportGroup from "./components/VProjectReportGroup";
import ProjectFlagService from "@/services/ProjectFlagService";

export default {
    components: { VProjectReportGroup },
    data() {
        return {
            loading: true,
            projects: {}
        };
    },

    async created() {
        await this.fetchData();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                const { data } = await ProjectFlagService.GET_flagReport();
                this.projects = data.data;
            } catch (e) {}
        },
        showTimeline(project_id) {
            this.timelineContext = project_id;
            this.showEditTimeline = true;
        }
    }
};
</script>

<template>
    <div class="v-planning-report">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper" v-if="true">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else>
                        <v-project-report-group
                            :title="$t('project.group_on_track')"
                            normal
                            :flags="projects.normal"
                        />
                        <v-project-report-group
                            alarm="orange"
                            :title="$t('project.group_at_risk')"
                            :flags="projects.orange"
                        />
                        <v-project-report-group
                            alarm="red"
                            :title="$t('project.group_realized_risk')"
                            :flags="projects.red"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

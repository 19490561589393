import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store";

Vue.use(VueI18n);

const commonNumberFormats = {
    full: {
        style: "decimal",
        minimumFractionDigits: 10,
        maximumFractionDigits: 15
    },
    usage: {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 15
    },
    money: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 3
    },
    decimal: {
        style: "decimal",
        minimumFractionDigits: 3
    },
    onedec: {
        style: "decimal",
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    },
    twodec: {
        style: "decimal",
        minimumFractionDigits: 2
    }
};

// EXTRA CODE START
const numberFormats = {
    "en-DK": commonNumberFormats,
    "da-DK": commonNumberFormats,
    "de-DE": commonNumberFormats
};

const setDateTimeFormats = {
    short: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    },
    dm: {
        month: "2-digit",
        day: "2-digit"
    },
    longMonthOnly: {
        year: "numeric",
        month: "long"
    },
    long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: false
    },
    longFull: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false
    },
    dow: {
        weekday: "short"
    },
    ldow: {
        weekday: "long"
    },
    fullDate: {
        year: "numeric",
        month: "long",
        weekday: "long",
        day: "2-digit"
    },
    fullDateTime: {
        year: "numeric",
        month: "long",
        weekday: "long",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: false
    }
};

const dateTimeFormats = {    
    "en-DK": setDateTimeFormats,    
    "da-DK": setDateTimeFormats,    
    "de-DE": setDateTimeFormats
};

// EXTRA CODE END

function loadLocaleMessages() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
}
/*
locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',

  da-DK
  language-territory

  da-DK:
  - language:which lang file is used
  - territory:formatting rules

  Changing locales: https://kazupon.github.io/vue-i18n/guide/locale.html
  setLocale('locale');

*/
console.log("SIOUREIURIEIREIRUOAEOIREARIOAEURIEA", store.getters["auth/getUserLocale"]);
export default new VueI18n({
    // locale: 'en-DK',
    locale: store.getters["auth/getUserLocale"],
    fallbackLocale: "en",
    messages: loadLocaleMessages(),
    numberFormats,
    dateTimeFormats,
    silentTranslationWarn: true
});

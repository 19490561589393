import Http from "@/libraries/Http";

export default {
    apiVersion: "v1",
    RESTroute: "projects",

    async PUT_resetTimeline(project_id, data = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/reset-timeline/${project_id}`,
            data
        );
    },

    async PUT_legacyAttachMilestones(project_id, data = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/legacy-attach-milestones/${project_id}`,
            data
        );
    },

    async GET_planningPortfolio(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/planning-portfolio`,
            { params: params }
        );
    },

    async GET_projectInterface(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/get-interface/${project_id}`,
            { params: params }
        );
    },

    async GET_projectsPerformance(reportType, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/projects-performance/${reportType}`,
            { params: params }
        );
    },

    async PUT_changeCustomer(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/change-customer`,
            data
        );
    },

    async GET_canChangeCustomer(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/can-change-customer/${project_id}`,
            { params: params }
        );
    },

    async GET_fullOverview(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/full-overview`, {
            params: params
        });
    },

    async GET_myOverview(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/my-overview`, {
            params: params
        });
    },

    async GET_dropdown(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/dropdown`, {
            params: params
        });
    },

    async GET_globalStatistics(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/global-statistics`,
            { params: params }
        );
    },

    /**
     * Regular projects
     * @param {Reg} project_id
     * @param {*} params
     * @returns
     */
    async GET_statistics(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/statistics/${project_id}`,
            { params: params }
        );
    },

    /**
     * Contract projects
     * @param {Contrac} project_id
     * @param {*} params
     * @returns
     */
    async GET_contractStatistics(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/contract-statistics/${project_id}`,
            { params: params }
        );
    },

    /**
     * Create a project from a contract
     * @param {C} contract_id
     * @param {*} data
     * @returns
     */
    async POST_fromContract(contract_id, data) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/create-from-contract/${contract_id}`,
            data
        );
    },

    async GET_discount(project_id) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/discount/${project_id}`
        );
    },

    async GET_customerProjectsData(customer_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/customer-projects-data/${customer_id}`,
            { params: params }
        );
    },

    /**
     * Get active and on hold projects (for time registration)
     * @param {*} customer_id
     */
    async GET_customerProjectsDropdown(customer_id) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/customer-projects-dropdown/${customer_id}`
        );
    },

    /**
     * Get data for the joint dropdown customer/project
     */
    async GET_jointDropdown(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/joint-dropdown`,
            { params: params }
        );
    },

    /**
     * Register favorite change
     * @param {} project_id
     * @param {*} flag
     * @returns
     */
    async changeFavorite(project_id, flag) {
        let data = {
            project_id: project_id,
            flag: flag ? "y" : "n"
        };

        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/change-favorite`,
            data
        );
    },

    /**
     * Return def. hour price for a given user in a given project
     * @param {} project_id
     * @param {*} user_id
     */
    async GET_defaultHourPrice(project_id, user_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/default-hour-price/${project_id}/${user_id}`,
            { params: params }
        );
    },

    async GET_participatingDropdown(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/participating-users/${project_id}`,
            { params: params }
        );
    },

    /**
     * Get all users except those listed in the project
     * @param {} params
     * @returns
     */
    async GET_availableUsersDropdown(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/available-users/${project_id}`,
            { params: params }
        );
    },

    /**
     * Check if a given user is responsible for a given project
     * @param {} project_id
     * @param {*} params
     * @returns
     */
    async GET_isResponsible(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/is-responsible/${project_id}`,
            { params: params }
        );
    },

    /**
     * Create a link between a user and a project
     * @param {} data
     * @returns
     */
    async POST_assignUser(data) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/assign-user`,
            data
        );
    },

    /**
     * Fetch an assignment entity for editing the assignment between a project and a user
     * @param {*} project_id
     * @param {*} user_id
     * @param {*} params
     * @returns
     */
    async GET_fetchAssignment(project_id, user_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/fetch-assignment/${project_id}/${user_id}`,
            { params: params }
        );
    },

    /**
     * Edit a link between a user and a project
     * @param {} data
     * @returns
     */
    async PUT_editAssignment(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/assign-user`,
            data
        );
    },

    /**
     * Get all resources
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {
            params: params
        });
    },

    /**
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {
            params: params
        });
    },

    /**
     * Create resource
     * POST /resource
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /**
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/${data.id}`,
            data
        );
    },

    /**
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }
};

<script>
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import ProjectFlagService from "@/services/ProjectFlagService";
import UserService from "@/services/UserService";
import VFilters from "@/components/Search/VFilters";
import VStats from "./components/VStats";

export default {
    components: { VFilters, VStats },
    data() {
        return {
            loading: true,
            milestones: [],
            meta: {},
            users: [],
            stats: {
                flags: 0,
                statuses: 0
            }
        };
    },
    computed: {
        statuses() {
            return [
                {
                    value: "1",
                    label: this.$t(
                        "project_milestone.status_awaiting_confirmation"
                    )
                },
                {
                    value: "5",
                    label: this.$t("project_milestone.status_done")
                }
            ];
        }
    },
    async created() {
        this.$aclGuard(this.$ACL.CUSTOM_PLANNING_MFA);
        this.initSearch();
        await this.fetchUsers();
        await this.fetchData();
        this.$apiEnableWatcher();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                const { data } = await ProjectMilestoneService.GET_mfaStatus(
                    params
                );
                this.milestones = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd e", e);
            }

            this.fetchFlagStats();
        },
        async fetchFlagStats() {
            try {
                const { data } = await ProjectFlagService.GET_flagStats();
                this.stats = data.data;
            } catch (e) {}
        },
        initSearch() {
            // status = 1 needs conf
            // status = 5 done
            this.apiSearchFilter = {
                status: "1"
            };

            this.apiPaginator.sort = "done_initiated_date";
            this.apiPaginator.direction = "desc";
        },
        async fetchUsers() {
            try {
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers exception", e);
            }
        },
        /**
         * Handle action button click (confirm or revert)
         * @param action
         * @param milestone
         */
        actionButton(action, milestone) {
            console.log("action", action);
            if (action == "confirm") {
                this.actionButtonConfirm(milestone);
            } else if (action == "revert") {
                this.actionButtonRevert(milestone);
            } else if (action == "reset") {
                this.actionButtonReset(milestone);
            }
        },
        async actionButtonRevert(m) {
            this.$swal({
                icon: "warning",
                text: this.$t("project_milestone.swal_revert_status_change"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        await ProjectMilestoneService.PUT_revertStatusChangeRequest(
                            m.id
                        );
                        await this.fetchData();
                        this.$notify({
                            message: this.$t(
                                "planning.notify_status_change_reverted"
                            ),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });
                    } catch (e) {}
                }
            });
        },
        async actionButtonReset(m) {
            this.$swal({
                icon: "warning",
                text: this.$t("project_milestone.swal_reset_status_change"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        await ProjectMilestoneService.PUT_resetStatusChangeRequest(
                            m.id
                        );
                        await this.fetchData();
                        this.$notify({
                            message: this.$t(
                                "planning.notify_status_change_reset"
                            ),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });
                    } catch (e) {}
                }
            });
        },
        async actionButtonConfirm(m) {
            this.$swal({
                icon: "warning",
                text: this.$t("project_milestone.swal_confirm_status_change"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        await ProjectMilestoneService.PUT_confirmStatusChangeRequest(
                            m.id
                        );
                        await this.fetchData();
                        this.$notify({
                            message: this.$t(
                                "planning.notify_status_change_confirmed"
                            ),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });
                    } catch (e) {}
                }
            });
        }
    }
};
</script>

<template>
    <div class="v-project-flags-index">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-stats :stats="stats" view="status" />
                </div>
                <div class="col-12">
                    <v-filters
                        :enable-filters="true"
                        v-model="apiSearchFilter.search"
                        :search="true"
                        :start-open="true"
                        :search-placeholder="
                            $t('flagging.search_in_milestones')
                        "
                        @reset-search="initSearch"
                    >
                        <template slot="default">
                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.status"
                                    :placeholder="
                                        $t('flagging.placeholder_status')
                                    "
                                    type="multiselect"
                                    :multiple="false"
                                    :options="statuses"
                                />
                            </div>
                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.done_init_user_id"
                                    :placeholder="$t('flagging.done_init_user')"
                                    type="multiselect"
                                    :multiple="false"
                                    :options="users"
                                />
                            </div>
                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="
                                        apiSearchFilter.done_confirmed_user_id
                                    "
                                    :placeholder="
                                        $t('flagging.done_confirmed_user')
                                    "
                                    type="multiselect"
                                    :multiple="false"
                                    :options="users"
                                />
                            </div>
                        </template>
                    </v-filters>
                </div>
                <div class="col-12">
                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="milestones.length > 0">
                            <v-table
                                class="table table-mg table-sm table-striped"
                            >
                                <v-thead>
                                    <v-tr>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="done_initiated_date"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("flagging.tbl_hdr_date")
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="status"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "flagging.tbl_hdr_status"
                                                    )
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name=""
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "flagging.tbl_hdr_project"
                                                    )
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name=""
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "flagging.tbl_hdr_milestone"
                                                    )
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name=""
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "flagging.tbl_hdr_requestor"
                                                    )
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name=""
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "flagging.tbl_hdr_description"
                                                    )
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name=""
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "flagging.tbl_hdr_confirmed_date"
                                                    )
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name=""
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "flagging.tbl_hdr_confirmed_user"
                                                    )
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th></v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr v-for="m in milestones" :key="m.id">
                                        <v-td v-if="false"> {{ m }}</v-td>
                                        <v-td>
                                            {{
                                                $d(
                                                    new Date(
                                                        m.done_initiated_date
                                                    ),
                                                    "short"
                                                )
                                            }}
                                        </v-td>
                                        <v-td>{{ m.status_name }}</v-td>
                                        <v-td>
                                            <router-link
                                                :to="{
                                                    name: 'ProjectDisplay',
                                                    params: {
                                                        project_id: m.project.id
                                                    }
                                                }"
                                                >{{
                                                    m.project.title
                                                }}</router-link
                                            >
                                        </v-td>
                                        <v-td>
                                            M{{ m.m_ident }}
                                            <div class="small text-muted">
                                                {{ m.name }}
                                            </div>
                                        </v-td>
                                        <v-td>
                                            {{ m.init_user.name }}
                                        </v-td>
                                        <v-td>
                                            <v-newlines :text="m.done_note" />
                                        </v-td>
                                        <v-td>
                                            <template
                                                v-if="m.done_confirmed_date"
                                                >{{
                                                    $d(
                                                        new Date(
                                                            m.done_confirmed_date
                                                        ),
                                                        "short"
                                                    )
                                                }}</template
                                            >
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td>
                                            <template
                                                v-if="m.done_confirmed_user_id"
                                                >{{ m.confirmed_user.name }}
                                            </template>
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td>
                                            <base-dropdown
                                                :menu-on-right="true"
                                                :has-toggle="false"
                                            >
                                                <template slot="title-container"
                                                    ><base-button
                                                        class="mr-0"
                                                        type="primary"
                                                        size="sm"
                                                        ><i
                                                            class="far fa-hand-pointer"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "flagging.take_action"
                                                            )
                                                        }}</base-button
                                                    ></template
                                                >
                                                <div
                                                    v-if="m.status === 1"
                                                    class="dropdown-item"
                                                    @click="
                                                        actionButton(
                                                            'confirm',
                                                            m
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="far fa-fw fa-check"
                                                    ></i>
                                                    {{
                                                        $t(
                                                            "project_milestone.set_to_ready"
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    v-if="m.status === 5"
                                                    class="dropdown-item text-danger"
                                                    @click="
                                                        actionButton(
                                                            'revert',
                                                            m
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="far fa-fw fa-times"
                                                    ></i>
                                                    {{
                                                        $t(
                                                            "project_milestone.set_revert_to_awaiting_confirmation"
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    v-if="m.status !== 0"
                                                    class="dropdown-item text-danger"
                                                    @click="
                                                        actionButton(
                                                            'reset',
                                                            m
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="far fa-fw fa-trash"
                                                    ></i>
                                                    {{
                                                        $t(
                                                            "project_milestone.set_reset_to_awaiting"
                                                        )
                                                    }}
                                                </div>
                                            </base-dropdown>

                                           
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>

                            <div
                                class="paginator d-flex justify-content-center"
                            >
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    :total="meta.pagination.total"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <div class="alert alert-warning mx-4" v-else>
                            {{ $t("flagging.no_status_change_requests_found") }}
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

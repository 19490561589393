var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-planning-portfolio"},[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('v-filters',{attrs:{"enable-filters":true,"search":true,"start-open":"","search-placeholder":_vm.$t('projects.placeholder_search_in_projects')},on:{"reset-search":_vm.initSearch},model:{value:(_vm.apiSearchFilter.search),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "search", $$v)},expression:"apiSearchFilter.search"}},[_c('template',{slot:"default"},[_c('div',{staticClass:"col-lg-3 col-md-12"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t(
                                        'form_elems.portfolio.placeholder_all_types'
                                    ),"type":"multiselect","multiple":false,"options":_vm.optTypes},model:{value:(_vm.apiSearchFilter.project_type),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "project_type", $$v)},expression:"apiSearchFilter.project_type"}})],1),_c('div',{staticClass:"col-lg-3 col-md-12"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t(
                                        'form_elems.portfolio.placeholder_all_statuses'
                                    ),"type":"multiselect","multiple":false,"options":_vm.optStatuses},model:{value:(_vm.apiSearchFilter.status),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "status", $$v)},expression:"apiSearchFilter.status"}})],1),_c('div',{staticClass:"col-lg-3 col-md-12"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t(
                                        'form_elems.portfolio.placeholder_all_pms'
                                    ),"type":"multiselect","multiple":false,"options":_vm.users},model:{value:(
                                    _vm.apiSearchFilter.responsible_user_id
                                ),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "responsible_user_id", $$v)},expression:"\n                                    apiSearchFilter.responsible_user_id\n                                "}})],1),_c('div',{staticClass:"col-lg-3 col-md-12"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t(
                                        'form_elems.portfolio.placeholder_all_municipalitites'
                                    ),"type":"multiselect","multiple":false,"options":_vm.municipalities},model:{value:(_vm.apiSearchFilter.municipality_id),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "municipality_id", $$v)},expression:"apiSearchFilter.municipality_id"}})],1)])],2)],1),_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):(Object.keys(_vm.projects).length > 0)?_vm._l((_vm.meta.stages),function(stage){return _c('div',{key:stage.stage_id,staticClass:"v-portfolio-stage-container"},[_c('h1',{staticClass:"mx-4"},[_vm._v(" "+_vm._s(stage.name)+" ")]),_c('v-portfolio-stage',{attrs:{"projects":_vm.projects[stage.stage_id],"paginator":_vm.apiPaginator},on:{"sort":_vm.handleSort}})],1)}):_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("project.no_projects_found"))+" ")])])],2)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
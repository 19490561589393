/**
 * Based on:
 * https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/
 * https://github.com/gabbyprecious/vue-blog/tree/master/src
 *
 */
import PureLoginService from "@/services/PureLoginService";

// data()
const state = () => ({
    authUser: null,
    impersonated: false
});

// computed()
const getters = {
    isAuthenticated(state) {
        if (state.authUser !== null && state.authUser.id > 0) {
            return true;
        } else {
            return false;
        }
    },
    getIsAdmin(state) {
        const role = state.authUser.acl_role;

        if (role === "ROOT" || role === "BOOKKEEPER" || role === "LEADER") {
            return true;
        } else {
            return false;
        }
    },
    getAclRole(state) {
        return state.authUser.acl_role;
    },
    getAclMap(state) {
        return state.authUser.acl_map;
    },
    getMyId(state) {
        return state.authUser.id;
    },
    getAuthUser(state) {
        return state.authUser;
    },
    getInvoiceSetup(state) {
        return state.authUser.invoice_setup;
    },
    getDailyTimeGoal(state) {
        return state.authUser.daily_time_goal;
    },
    userAvatar(state) {
        return state.authUser.avatar;
    },
    getUserLocale(state) {
        if (state.authUser === null) {
            if(process.env.VUE_APP_TENANT == "dk") {
                return "da-DK";
            } else if (process.env.VUE_APP_TENANT == "de") {
                return "de-DE";
            }
        } else {
            return state.authUser.locale;
        }
    },
    getIsImpersonated(state) {
        return state.getIsImpersonated;
    }
};

// methods -> dispatch()
const actions = {
    async authenticate(context, payload) {
        try {
            const r = await PureLoginService.login(
                payload.username,
                payload.password
            );
            context.commit("setUser", r.data.data);
            console.log("[vuex] authenticate context", r.data.data);
        } catch (e) {
            console.log("[vuex] authenticate exception", e);
            context.commit("setUser", null);
        }
    },

    async logout(context) {
        try {
            await PureLoginService.logout();
            context.commit("logoutUser");
        } catch (e) {}
    },

    /**
     * Update user data in session + vuex
     * @param {} context
     */
    async refreshAuthUserData(context) {
        try {
            const r = await PureLoginService.refreshMyAuthData();
            context.commit("setUser", r.data.data);
        } catch (e) {}
    },

    /**
     * OAuth2 login context - hydrate required values
     * @param {} context
     * @returns
     */
    async oauth2HydrateStore(context) {
        return PureLoginService.refreshMyAuthData()
            .then(r => {
                console.log("COMMIT", r.data.data);
                context.commit("setUser", r.data.data);
            })
            .catch(e => {});
    }
};

// change store -> commit()
const mutations = {
    setUser(state, payload) {
        console.log("SETUSER", payload);
        state.authUser = payload;
    },

    logoutUser(state) {
        sessionStorage.clear();
        state.authUser = null;
    },

    updateAvatarUrl(state, payload) {
        state.authUser.avatar = payload;
    },

    updateDailyTimeGoal(state, payload) {
        state.authUser.daily_time_goal = payload;
    },
    setImpersonation(state, payload) {
        state.getIsImpersonated = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
